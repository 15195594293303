import aaa from "./modules/aaa";
import ada from "./modules/ada";
import astra from "./modules/astra";
import alert from "./modules/modals/alert";
import contact from "./modules/modals/contact";
import mobilTireScheduleService from "./modules/modals/mobile-tire-schedule-service";
import video from "./modules/modals/video";
import zipCodeGate from "./modules/modals/zip-code-gate";
import notificationBar from "./modules/notificationBar";
import pagination from "./modules/pagination";
import sliders from "./modules/sliders";
import utils from "./modules/utils";

// Utils
// Dev helpers
utils.e10kDevHelpers();

// Format phone numbers automatically
utils.e10kFormatPhoneNumbers();

// Remove empty paragraph tags
utils.e10kRemoveEmptyPTags(
	document.querySelectorAll(
		".site-above-footer-wrap .ast-builder-html-element, .site-footer-primary-section-2 .ast-builder-html-element, .site-footer-primary-section-3 .ast-builder-html-element, .site-footer-primary-section-4 .ast-builder-html-element"
	)
);

// Open links in new tab
utils.e10kOpenLinkInNewTab(
	document.querySelectorAll(
		".blog .wp-block-categories-list .cat-item-32 > a"
	)
);

// Video fade
utils.e10kVideoFadeIn(
	document.querySelectorAll(
		".background-video video, .background-video iframe"
	)
);

// AAA - Triple AAA related scripts
aaa.init();

// ADA - Accessibility scripts, mainly fixes
ada.init();

// Astra - Theme scripts
astra.init();

// Pagination
pagination.init();

// Notification bar
notificationBar.init();

// Modals
alert.init();
// contact.init();
mobilTireScheduleService.init();
video.init();
zipCodeGate.init();

// Sliders
sliders.init();
