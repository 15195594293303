function init() {
	// Append "#job-list" anchor to pagination for jobs
	const jobListEl = document.querySelector("#jobs-list");

	if (jobListEl) {
		const jobListPaginationAppendAnchor =
			jobListPaginationUpdate(jobListEl);
	}
}

function jobListPaginationUpdate(jobListEl) {
	const pageLinks = jobListEl.querySelectorAll(".wp-pagenavi > a");

	pageLinks.forEach((el) => {
		const href = el.href;

		el.setAttribute("href", href + "#jobs-list");
	});
}

export default { init };
