import Cookies from "js-cookie";
import utils from "../utils";

function init() {
	const zipCodeGateModalEl = document.querySelector(".zip-code-gate-popup");

	if (zipCodeGateModalEl) {
		const zipCodeGateModalForm = dialogEventListeners({
			open: "auto",
			dialog: zipCodeGateModalEl,
			input: document.querySelector(
				".zip-code-gate-popup .zip-code-input"
			),
			button: document.querySelector(
				".zip-code-gate-popup .zip-code-submit"
			)
		});
	}
}

// Check zip code
async function checkZipCode(zipCode, dialog) {
	const url = "https://ww2.aaa.com/RESTWS/aaa/services/geo/postal/" + zipCode;

	// Testing
	// const response = await fetch(url);
	// const data = await response.json();
	// console.log(data);

	// Check club code
	// If AAA Hudson Valley club code (080)
	// Set cookie
	// Close modal
	closeDialog(dialog);

	// If another club code
	// Redirect to main AAA site
}

// Handle form submit
function handleFormSubmit(event, input, dialog) {
	// Prevent form submit
	event.preventDefault();

	// Get input (zip code) value
	const zipCode = input.value;
	const formValidationErrorEl = document.querySelector(
		".zip-code-gate-popup .form-validation-error"
	);

	// If field is empty, show error
	if ("" === zipCode) {
		formValidationErrorEl.innerHTML = "<p>Please enter a zip code.</p>";
		// If field has a value
	} else {
		checkZipCode(zipCode, dialog);
	}
}

// Check cookie
function checkCookie() {
	// console.log("Check cookie!");

	// Our cookie
	// const zipCodeGateModalCookie = Cookies.get(
	// 	"aaa-hv-wp-zip-code-gate-popup"
	// );

	// AAA (.aaa.com cookie)
	// Set test cookie (AAA Northway)
	// Cookies.set("zipcode", "12027|AAA|104|PC", {
	// 	expires: 1
	// });

	const aaaZipCodeCookie = Cookies.get("zipcode", { domain: ".aaa.com" });
	let clubCode = "";

	// If cookie exists, determine if value contains correct club code (80)
	if (aaaZipCodeCookie) {
		clubCode = aaaZipCodeCookie.split("|")[2].trim();

		// Else return false and open the dialog
	} else {
		return false;
	}

	// If club code is correct (80) return true, we don't need to show dialog
	if (clubCode === "80") {
		return true;

		// Else return "redirect" as we will need to redirect to AAA
	} else {
		return "redirect";
	}
}

// Set cookie
// function setCookie() {
// 	console.log("Set cookie!");

// 	// const expires = 1 / 48; // 30 minutes
// 	const expires = 1; // 1 day
// 	Cookies.set("aaa-hv-wp-zip-code-gate-popup", "closed", {
// 		expires: expires
// 	});
// }

// Open dialog
function openDialog(dialog) {
	// console.log("Open dialog!");

	setTimeout(() => {
		dialog.showModal();
	}, 2000);
}

// Close dialog
function closeDialog(dialog) {
	// console.log("Close dialog!");

	dialog.close();
	// setCookie();
}

// Dialog add event listeners
function dialogEventListeners(args) {
	// Open automatically
	if (args.open === "auto") {
		// Check for cookie and if it contains correct club code (80)
		const cookie = checkCookie();

		// Open dialog if there is no cookie
		if (cookie === false) {
			openDialog(args.dialog);
		}

		// Redirect to main AAA site if a different club, let them handle the redirect to the correct club
		if (cookie === "redirect") {
			// console.log("Redirect to AAA!");
			window.location.href = "https://www.aaa.com/";
		}
	}

	// Disable ESC key
	args.dialog.addEventListener("cancel", (e) => {
		e.preventDefault();
	});

	// Close
	// On ::backdrop click
	// if (args.dialog) {
	// 	args.dialog.addEventListener("click", (event) => {
	// 		if (event.target.nodeName === "DIALOG") {
	// 			closeDialog(args.dialog);
	// 		}
	// 	});
	// }

	// On close button click
	// if (args.close) {
	// 	args.close.addEventListener("click", (event) => {
	// 		closeDialog(args.dialog);
	// 	});
	// }

	// On other button clicks
	// if (args.buttons) {
	// 	args.buttons.forEach((button, index) => {
	// 		button.addEventListener("click", (event) => {
	// 			closeDialog(args.dialog);
	// 		});
	// 	});
	// }

	// On submit
	// if (args.button) {
	// 	args.button.addEventListener("click", (event) => {
	// 		handleFormSubmit(event, args.input, args.dialog);
	// 	});
	// }
}

export default { init };
