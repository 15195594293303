import Cookies from "js-cookie";

const body = document.body;

function init() {
	const notificationBarEl = document.querySelector(".notification-bar");

	if (notificationBarEl) {
		// Event listeners
		const notificationBar = notificationEventListeners({
			bar: notificationBarEl,
			close: document.querySelector(".notification-bar .close")
		});

		// Add body class
		const notificationBarBodyClass = setBodyClass();

		// Update #page bottom padding
		const pageBottomPadding = updatePageBottomPadding();
	}
}

// Update #page bottom padding to accomodate notification bar
function updatePageBottomPadding() {
	const pageEl = document.querySelector("#page");
	const notificationBar = document.querySelector(".notification-bar");
	const barHeight = notificationBar.offsetHeight;
	const barDisplay = window.getComputedStyle(notificationBar, null).display;

	// Only add padding if bar is displayed
	if (barDisplay !== "none") {
		pageEl.style.paddingBottom = `${barHeight - 2}px`;
	} else {
		pageEl.style.paddingBottom = `0px`;
	}
}

// Set body class
function setBodyClass() {
	const notificationBarCookie = Cookies.get("aaa-hv-wp-notification-bar");

	if (
		!document.body.classList.contains("notification-bar-enabled") &&
		!notificationBarCookie
	) {
		body.classList.add("notification-bar-enabled");
	} else {
		body.classList.remove("notification-bar-enabled");
	}
}

// Set cookie
function setCookie() {
	// const expires = 30; // 30 days
	const expires = 1; // 1 day
	Cookies.set("aaa-hv-wp-notification-bar", "closed", { expires: expires });
}

// Close bar
function closeNotificationBar(bar) {
	body.classList.remove("notification-bar-enabled");
	setCookie();
	setBodyClass();
}

// Add event listeners
function notificationEventListeners(args) {
	// On close button click
	args.close.addEventListener("click", (event) => {
		closeNotificationBar(args.bar);
		setCookie();
		updatePageBottomPadding();
	});

	// On window resize
	window.addEventListener("resize", updatePageBottomPadding);
}

export default { init };
