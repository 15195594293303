// Zip Code Gate - See /modals/zip-code-gate.js

function init() {
	// console.log("Do AAA scripts.");
	// console.log(jQuery);

	const memberLoggedIn = memberScripts();
}

async function checkMemberStatus() {
	const url = "https://members.hudsonvalley.aaa.com/member-status";

	// Testing
	const response = await fetch(url, {
		// mode: "cors",
		// method: "get"
	});
	const data = await response.json();
	// console.log(data);

	// return await fetch(url).then((response) => response.json());
	return data;
}

function memberScripts() {
	// checkMemberStatus()
	// 	.then((response) => {
	// 		const status = response.logged_in;

	// 		// Add "aaa-member-logged-in" class to body if logged in, "aaa-member-logged-out" if logged out
	// 		if (status) {
	// 			// console.log("User logged in.");
	// 			document.body.classList.remove("aaa-member-logged-out");
	// 			document.body.classList.add("aaa-member-logged-in");
	// 		} else {
	// 			// console.log("User NOT logged in.");
	// 			document.body.classList.remove("aaa-member-logged-in");
	// 			document.body.classList.add("aaa-member-logged-out");
	// 		}
	// 	})
	// 	.catch(function (err) {
	// 		console.log("Error: " + err);
	// 		throw err;
	// 	});

	// jQuery version
	jQuery.ajax({
		url: "https://members.hudsonvalley.aaa.com/member-status",
		dataType: "jsonp",
		success: function (data) {
			// console.log(data);
			const status = data.logged_in;

			if (status) {
				// console.log("User logged in.");
				document.body.classList.remove("aaa-member-logged-out");
				document.body.classList.add("aaa-member-logged-in");
			} else {
				// console.log("User NOT logged in.");
				document.body.classList.remove("aaa-member-logged-in");
				document.body.classList.add("aaa-member-logged-out");
			}
		}
	});
}

export default { init };
