import Cookies from "js-cookie";
import utils from "../utils";

function init() {
	const alertPopupEl = document.querySelector(".alert-popup");

	if (alertPopupEl) {
		const alertPopupForm = dialogEventListeners({
			open: "auto",
			dialog: alertPopupEl,
			close: document.querySelector(".alert-popup .close"),
			buttons: document.querySelectorAll(".alert-popup .button")
		});
	}
}

// Set cookie
function setCookie() {
	// console.log("Set cookie!");

	// const expires = 1 / 48; // 30 minutes
	const expires = 1; // 1 day
	Cookies.set("aaa-hv-wp-alert-popup", "closed", { expires: expires });
}

// Open dialog
function openDialog(dialog) {
	// console.log("Open dialog!");

	setTimeout(() => {
		dialog.showModal();
	}, 2000);
}

// Close dialog
function closeDialog(dialog) {
	// console.log("Close dialog!");

	dialog.close();
	setCookie();
}

// Dialog add event listeners
function dialogEventListeners(args) {
	// Open automatically
	if (args.open === "auto") {
		// Cookie handling
		const alertPopupCookie = Cookies.get("aaa-hv-wp-alert-popup");

		if (!alertPopupCookie) {
			openDialog(args.dialog);
		}
	}

	// Close
	// On ::backdrop click
	if (args.dialog) {
		args.dialog.addEventListener("click", (event) => {
			if (event.target.nodeName === "DIALOG") {
				closeDialog(args.dialog);
			}
		});
	}

	// On close button click
	if (args.close) {
		args.close.addEventListener("click", (event) => {
			closeDialog(args.dialog);
		});
	}

	// On other button clicks
	if (args.buttons) {
		args.buttons.forEach((button, index) => {
			button.addEventListener("click", (event) => {
				closeDialog(args.dialog);
			});
		});
	}
}

export default { init };
