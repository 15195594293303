import EmblaCarousel from "embla-carousel";
import AutoScroll from "embla-carousel-auto-scroll";
import ClassNames from "embla-carousel-class-names";

function init() {
	// console.log(EmblaCarousel);

	// Banner slider
	if (document.querySelector(".banner-slider .embla")) {
		bannerSlider();
	}

	// Grid slider
	const gridSliderEl = document.querySelectorAll(
		".grid-slider.embla:not(.mobile-slider-false)"
	);
	if (gridSliderEl) {
		gridSliderEl.forEach((el) => {
			gridSlider(el);
		});
	}

	// Logo slider
	if (document.querySelector(".logo-slider.embla")) {
		logoSlider();
	}

	// Memberships slider
	if (document.querySelector(".memberships-slider.embla")) {
		membershipsSlider();
	}
}

// Previous/next navigation
export const addPrevNextBtnsClickHandlers = (embla, prevBtn, nextBtn) => {
	const scrollPrev = () => embla.scrollPrev();
	const scrollNext = () => embla.scrollNext();
	prevBtn.addEventListener("click", scrollPrev, false);
	nextBtn.addEventListener("click", scrollNext, false);

	return () => {
		prevBtn.removeEventListener("click", scrollPrev, false);
		nextBtn.removeEventListener("click", scrollNext, false);
	};
};

export const addTogglePrevNextBtnsActive = (embla, prevBtn, nextBtn) => {
	const togglePrevNextBtnsState = () => {
		if (embla.canScrollPrev()) prevBtn.removeAttribute("disabled");
		else prevBtn.setAttribute("disabled", "disabled");

		if (embla.canScrollNext()) nextBtn.removeAttribute("disabled");
		else nextBtn.setAttribute("disabled", "disabled");
	};

	embla
		.on("select", togglePrevNextBtnsState)
		.on("init", togglePrevNextBtnsState)
		.on("reInit", togglePrevNextBtnsState);

	return () => {
		prevBtn.removeAttribute("disabled");
		nextBtn.setAttribute("disabled", "disabled");
	};
};

// Dot navigation
export const addDotBtnsAndClickHandlers = (embla, dots) => {
	let dotNodes = [];

	const addDotBtnsWithClickHandlers = () => {
		dots.innerHTML = embla
			.scrollSnapList()
			.map(
				() =>
					'<button class="embla__dot" type="button"><span class="visually-hidden">Slide dot</span></button>'
			)
			.join("");

		dotNodes = Array.from(dots.querySelectorAll(".embla__dot"));
		dotNodes.forEach((dotNode, index) => {
			dotNode.addEventListener(
				"click",
				() => embla.scrollTo(index),
				false
			);
		});
	};

	const toggleDotBtnsActive = () => {
		const previous = embla.previousScrollSnap();
		const selected = embla.selectedScrollSnap();
		dotNodes[previous].classList.remove("embla__dot--selected");
		dotNodes[selected].classList.add("embla__dot--selected");
	};

	embla
		.on("init", addDotBtnsWithClickHandlers)
		.on("reInit", addDotBtnsWithClickHandlers)
		.on("init", toggleDotBtnsActive)
		.on("reInit", toggleDotBtnsActive)
		.on("select", toggleDotBtnsActive);

	return () => {
		dots.innerHTML = "";
	};
};

// Autoplay
const autoPlay = (embla, viewPort, interval) => {
	const lastIndex = embla.scrollSnapList().length - 1;
	const state = { timer: 0 };

	const play = () => {
		stop();
		requestAnimationFrame(
			() => (state.timer = window.setTimeout(next, interval))
		);
	};

	const stop = () => {
		window.clearTimeout(state.timer);
		state.timer = 0;
	};

	const next = () => {
		if (embla.selectedScrollSnap() === lastIndex) {
			embla.scrollTo(0);
		} else {
			embla.scrollNext();
		}
		play();
	};

	viewPort.addEventListener("mouseenter", stop, false);
	viewPort.addEventListener("mouseleave", play, false);
	viewPort.addEventListener("touchstart", stop, false);
	play();

	return { play, stop };
};

// Banner slider
function bannerSlider() {
	const wrap = document.querySelector(".banner-slider .embla");
	const viewPort = wrap.querySelector(".banner-slider .embla__viewport");
	const prevBtn = wrap.querySelector(".banner-slider .embla__button--prev");
	const nextBtn = wrap.querySelector(".banner-slider .embla__button--next");
	const dots = wrap.querySelector(".banner-slider .embla__dot_navigation");
	const slides = wrap.querySelectorAll(".banner-slider .slide");
	let status = true;

	// Add not disabled class if slider has 1 slide
	if (slides.length === 1) {
		wrap.classList.add("disabled");
		status = false;
	}

	const embla = EmblaCarousel(
		viewPort,
		{
			active: status,
			align: "start",
			loop: true
		},
		[ClassNames()]
	);

	// Previous/next navigation
	const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
		embla,
		prevBtn,
		nextBtn
	);

	const removeTogglePrevNextBtnsActive = addTogglePrevNextBtnsActive(
		embla,
		prevBtn,
		nextBtn
	);

	// Dot navigation
	const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
		embla,
		dots
	);

	// Autoplay
	const auto = autoPlay(embla, viewPort, 5000);

	// Ready
	const toggleEmblaReady = (event) => {
		const isResizeEvent = event === "resize";
		const toggleClass = isResizeEvent ? "remove" : "add";
		viewPort.classList[toggleClass]("embla--is-ready");
		if (isResizeEvent) embla.reInit();
	};

	embla.on("init", toggleEmblaReady);
	embla.on("resize", toggleEmblaReady);
	embla.on("reInit", toggleEmblaReady);
}

// Grid slider
function gridSlider(el) {
	const wrap = el;
	const viewPort = wrap.querySelector(".grid-slider .embla__viewport");
	const dots = wrap.querySelector(".grid-slider .embla__dot_navigation");
	const embla = EmblaCarousel(
		viewPort,
		{
			active: true,
			align: "start",
			breakpoints: {
				"(min-width: 1200px)": { active: false }
			},
			containScroll: "keepSnaps"
			// loop: true
		},
		[ClassNames()]
	);

	// Dot navigation
	const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
		embla,
		dots
	);

	// Ready
	const toggleEmblaReady = (event) => {
		const isResizeEvent = event === "resize";
		const toggleClass = isResizeEvent ? "remove" : "add";
		viewPort.classList[toggleClass]("embla--is-ready");
		if (isResizeEvent) embla.reInit();
	};

	embla.on("init", toggleEmblaReady);
	embla.on("resize", toggleEmblaReady);
	embla.on("reInit", toggleEmblaReady);
}

// Logo slider
function logoSlider() {
	const wrap = document.querySelector(".logo-slider.embla");
	const viewPort = wrap.querySelector(".logo-slider.embla .embla__viewport");
	const embla = EmblaCarousel(
		viewPort,
		{
			align: "start",
			draggable: false,
			loop: true
		},
		[
			AutoScroll({
				speed: 1.5,
				stopOnInteraction: false
			}),
			ClassNames()
		]
	);

	// const rafId = {};

	// const animate = () => {
	// 	if (!embla || !rafId.current) return;

	// 	const engine = embla.internalEngine();
	// 	engine.location.add(-0.75);
	// 	engine.target.set(engine.location);
	// 	engine.scrollLooper.loop(-1);
	// 	engine.slideLooper.loop();
	// 	engine.translate.to(engine.location);
	// 	rafId.current = requestAnimationFrame(animate);
	// };

	// const startAutoScroll = () => {
	// 	rafId.current = requestAnimationFrame(animate);
	// };

	// const stopAutoScroll = () => {
	// 	rafId.current = cancelAnimationFrame(rafId.current) || 0;
	// };

	const toggleEmblaReady = (event) => {
		const isResizeEvent = event === "resize";
		const toggleClass = isResizeEvent ? "remove" : "add";
		viewPort.classList[toggleClass]("embla--is-ready");
		if (isResizeEvent) embla.reInit();
	};

	embla.on("init", toggleEmblaReady);
	embla.on("resize", toggleEmblaReady);
	embla.on("reInit", toggleEmblaReady);
	// embla.on("pointerDown", stopAutoScroll);
	// embla.on("settle", startAutoScroll);
	// startAutoScroll();
}

// Memberships slider
function membershipsSlider() {
	const wrap = document.querySelector(".memberships-slider.embla");
	const viewPort = wrap.querySelector(".memberships-slider .embla__viewport");
	const dots = wrap.querySelector(
		".memberships-slider .embla__dot_navigation"
	);
	const embla = EmblaCarousel(
		viewPort,
		{
			active: true,
			align: "center",
			breakpoints: {
				"(min-width: 1200px)": { active: false }
			},
			// loop: true,
			startIndex: 1
		},
		[ClassNames()]
	);

	// Dot navigation
	const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
		embla,
		dots
	);

	// Ready
	const toggleEmblaReady = (event) => {
		const isResizeEvent = event === "resize";
		const toggleClass = isResizeEvent ? "remove" : "add";
		viewPort.classList[toggleClass]("embla--is-ready");
		if (isResizeEvent) embla.reInit();
	};

	embla.on("init", toggleEmblaReady);
	embla.on("resize", toggleEmblaReady);
	embla.on("reInit", toggleEmblaReady);
}

export default { init };
